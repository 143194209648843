import React, { useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";

import classes from "./Navbar.module.css";
import { hamburgerIcon, logo } from "../../images";

import { PiTelegramLogo, PiXLogo } from "react-icons/pi";
import { SlSocialLinkedin } from "react-icons/sl";

import { Link } from "react-scroll";
import { Button, Text } from "../common";
import clsx from "clsx";
import useOnClickOutside from "../../hooks";

const Navbar = () => {
  const navItems = [
    { navItem: "About us", to: "about" },
    { navItem: "Services", to: "services" },
    { navItem: "Why us", to: "why-us" },
    { navItem: "Testimonials", to: "testimonies" },
    { navItem: "Team", to: "team" },
    { navItem: "Blog", to: "blog" },
  ];
  const socialMedia = [
    {
      icon: <PiTelegramLogo className={classes.socialIcon} />,
      name: "Telegram",
      to: "https://t.me/taisiya_shuykova",
    },
    {
      icon: <SlSocialLinkedin className={classes.socialIcon} />,
      name: "Linkedin",
      to: "https://www.linkedin.com/company/cryptologicagency/",
    },
    {
      icon: <PiXLogo className={classes.socialIcon} />,
      name: "Twitter(X)",
      to: "https://x.com/CryptoLogicAgy",
    },
  ];
  const [sidebar, setSidebar] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setShowSocialMedia(false));
  return (
    <div className={[classes.wrapper, sidebar && classes.wrapperBg].join(" ")}>
      <header className={clsx(classes.header, "container")}>
        <Link
          className={clsx(classes.navItem, classes.logoContainer)}
          to="hero"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          onClick={() => setSidebar((prev) => !prev)}
        >
          {" "}
          <img src={logo} alt="#" className={classes.logo} />
        </Link>

        <div className={clsx(classes.navItems, sidebar && classes.sidebar)}>
          {navItems.map((el, i) => (
            <Link
              className={classes.navItem}
              key={i}
              to={el.to}
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              activeStyle={{ color: "#fff", fontWeight: "500" }}
              onClick={() => setSidebar((prev) => !prev)}
            >
              {el.navItem}
            </Link>
          ))}
          <div
            className={clsx(
              classes.buttonContainer,
              classes.mobileButtonContainer
            )}
          >
            <Button
              className={classes.button}
              onClick={() => setShowSocialMedia((prev) => !prev)}
            >
              Let’s talk
            </Button>{" "}
            <div className={clsx(classes.talkWithus)}>
              {socialMedia.map((el, i) => (
                <a
                  href={el.to}
                  rel="noreferrer"
                  target="_blank"
                  key={i}
                  className={classes.socialMedia}
                >
                  {el.icon}
                </a>
              ))}
            </div>
          </div>
          {sidebar && (
            <IoMdClose
              className={classes.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}
        </div>
        <div className={classes.buttonContainer} ref={dropdownRef}>
          <Button
            className={clsx(classes.button, classes.desktopButton)}
            onClick={() => setShowSocialMedia((prev) => !prev)}
          >
            Let’s talk
          </Button>{" "}
          <div
            className={clsx(
              classes.socialContainer,
              classes.dekstopSocialContainer,
              showSocialMedia && classes.showSocialContainer
            )}
          >
            {socialMedia.map((el, i) => (
              <a
                href={el.to}
                rel="noreferrer"
                target="_blank"
                key={i}
                className={classes.socialMedia}
                onClick={() => setShowSocialMedia((prev) => !prev)}
              >
                {el.icon}
                <Text lg base0 regular>
                  {el.name}
                </Text>
              </a>
            ))}
          </div>
          {!sidebar && (
            <img
              src={hamburgerIcon}
              className={classes.hamburgerIcon}
              alt="#"
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}
        </div>
      </header>
    </div>
  );
};

export default Navbar;
