import React from "react";
import classes from "./HeroSection.module.css";
import clsx from "clsx";

import Heading from "../../common/Heading/Heading";
import { arrow } from "../../../images";
import { Button, Text } from "../../common";

const HeroSection = () => {
  return (
    <>
      <section className={classes.wrapper} id="hero">
        <div className={clsx("container", classes.container)}>
          <div className={classes.headingContainer}>
            <Heading h1 className={classes.heading} font300>
              Where Every Question
            </Heading>
            <Heading h1 className={classes.secondLine} textRight font300>
              <img src={arrow} alt="#" className={classes.arrow} /> Has a{" "}
              <span className={classes.highlight}>Logical </span> Answer
            </Heading>
          </div>
          <Text base0 xl3 textCenter className={classes.info}>
            Project and executive branding, strategic content, connections, &
            more—elevate your initial concept to crypto success.
          </Text>
          <Button href="https://t.me/taisiya_shuykova">Get your answers</Button>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
