import React from "react";
import classes from "./Testimonial.module.css";
import clsx from "clsx";
import { go2nftLogo, syfuLogo } from "../../../images";
import { Heading, SectionHeading, Text } from "../../common";
const Testimonial = () => {
  const data = [
    {
      logo: syfuLogo,
      name: "Tomochika Kamiya",
      position: "Chief Executive Officer, SYFY",
      info: "What makes working with Taisiya so great is not just the knowledge and experience she brings, but also her deep understanding of our project. She thinks alongside us as a true team member. That's the main reason we trust her so much",
    },
    {
      logo: go2nftLogo,
      name: "Jessica Nascimento",
      position: "CEO of Go2NFT",
      info: "I have known the founder for many years and have worked with her on multiple projects. Her professionalism and dedication truly stand out. Her hard work and commitment to delivering exceptional results consistently exceed expectations.",
    },
  ];
  return (
    <section className={classes.wrapper} id="testimonies">
      <div className={clsx(classes.container, "container")}>
        <SectionHeading heading="Testimonials" type2 />
        <div className={classes.cards}>
          {data?.map((el, i) => (
            <div className={classes.card} key={i}>
              <img src={el.logo} alt="#" className={classes.logo} />

              <div className={classes.titleAndPosition}>
                {" "}
                <Heading xl5 regular base950 className={classes.info}>
                  {el.name}
                </Heading>
                <Heading xl2 regular base950 className={classes.position}>
                  {el.position}
                </Heading>
              </div>
              <Text xl base950 className={classes.info}>
                {el.info}
              </Text>
            </div>
          ))}
        </div>{" "}
      </div>
    </section>
  );
};

export default Testimonial;
