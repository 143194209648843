import React from "react";
import classes from "./OurBlog.module.css";
import { blogImg1, blogImg2, blogImg3 } from "../../../images";
import { Heading, SectionHeading, Text } from "../../common";
// import { Link } from "react-router-dom";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import clsx from "clsx";

const OurBlog = () => {
  const blogs = [
    {
      img: blogImg1,
      title: "10 Stages of Building a Crypto Startup: Key Obstacles and How to Overcome Them",
      info: "Learn the 10 critical stages of building a crypto startup and how most Web3 founders can overcome key obstacles.",
      date: "2nd October, 2024",
      link:"https://medium.com/@info_56978/10-stages-of-building-a-crypto-startup-key-obstacles-and-how-to-overcome-them-49cf8db0bf6b"
    },
    {
      img: blogImg2,
      title: "Building from the Ground Up: Fundraising for New Founders",
      info: "Discover working strategies for raising funds as a Web3 startup founder in the crypto space.",
      date: "2nd October, 2024",
      link:"https://medium.com/@info_56978/building-from-the-ground-up-fundraising-for-new-founders-dc73b1a3623d"
    },
  ];
  return (
    <section className={classes.wrapper} id="blog">
      <div className={clsx(classes.container, "container")}>
        <SectionHeading type2 heading="Our Blog" base0 />
        <div className={classes.blogs}>
          {" "}
          {blogs?.map((blog, i) => (
            <div className={classes.blog} key={i}>
              <img src={blog.img} alt={blog.title} className={classes.img} />
              <div className={classes.infoContainer}>
                <Heading xl4 regular>
                  {blog.title}
                </Heading>
                <Heading regular xl>
                  {blog.info}
                </Heading>
              </div>
              <div className={classes.bottomBar}>
                  <a className={classes.learnMore} href={blog.link} target="_blank" rel="noreferrer">                 
                    Read now
                  <MdOutlineArrowCircleRight className={classes.arrow} />
                  </a>
                <Text base regular base0 className={classes.date}>
                  {blog.date}
                </Text>
              </div>
            </div>
          ))}
        </div>{" "}
      </div>
    </section>
  );
};

export default OurBlog;
