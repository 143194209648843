import React from "react";
import classes from "./Footer.module.css";
import clsx from "clsx";
import { logo } from "../../images";
import { Text } from "../common";

const Footer = () => {
  const socialMedia = [
    { name: "Linkedin", link: "https://www.linkedin.com/company/cryptologicagency/" },
    { name: "Twitter(X)", link: "https://x.com/CryptoLogicAgy" },
    { name: "Telegram", link: "https://t.me/taisiya_shuykova" },
  ];
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.infoContainer}>
          <img src={logo} alt="#" className={classes.logo} />
          <Text xl>
            Demystifying cryptocurrency investments and strategies for newcomers
            by providing expert and consultancy services
          </Text>
        </div>

        <div className={classes.socialContainer}>
          {socialMedia.map((el, i) => (
            <a
              href={el.link}
              target="_blank"
              rel="noreferrer"
              key={i}
              className={classes.button}
            >
              <Text xl regular>
                {el.name}
              </Text>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Footer;
