import React from "react";
import classes from "./AboutUs.module.css";
import clsx from "clsx";
import { SectionHeading, Text } from "../../common";
const AboutUs = () => {
  return (
    <section className={classes.wrapper} id="about">
      <div className={clsx(classes.container, "container")}>
        <SectionHeading heading="About Us" />
        <div className={classes.infoContainer}>
          <Text xl3 base800>
            At Crypto Logic Agency, we specialize in guiding traditional
            businesses as they transition into the dynamic world of
            cryptocurrency.
          </Text>{" "}
          <Text xl3 base800>
            Our mission is to provide clarity in a complex market, offering
            personalized strategies that empower our clients to thrive.
          </Text>{" "}
          <Text xl3 base800>
            Our expertise extends from conceptualization to go-to-market
            strategies, ensuring your project is not just another name in the
            crowd but a recognized and respected brand.
          </Text>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
