import React from "react";
import classes from "./WhyUs.module.css";
import clsx from "clsx";
import {
  comprehensizeSolutions,
  industryExpertiseIcon,
  personalizedServiceIcon,
} from "../../../images";
import { Heading, SectionHeading, Text } from "../../common";
const WhyUs = () => {
  const facilities = [
    {
      icon: industryExpertiseIcon,
      title: "Industry Expertise",
      info: "With years of experience in the crypto industry, we understand the unique challenges and opportunities you face.",
    },
    {
      icon: personalizedServiceIcon,
      title: "Personalized Service",
      info: "We tailor our strategies to meet the specific needs of your project, ensuring a customized approach that delivers results.",
    },
    {
      icon: comprehensizeSolutions,
      title: "Comprehensive Solutions",
      info: "We offer a full suite of services designed to take your project from concept to launch and beyond.",
    },
  ];
  return (
    <section className={classes.wrapper} id="why-us">
      <div className={clsx(classes.container, "container")}>
        <div className={classes.headingContainer}>
          <SectionHeading heading="Why Us" reverse base0 />
        </div>
        {facilities?.map((facility, i) => (
          <div
            className={classes.facility}
            key={i}
            style={{ order: i + 1 > 1 && i + 1 }}
          >
            <Heading xl5>{facility.title}</Heading>
            <Text xl2 base0 className={classes.info}>
              {facility.info}
            </Text>
            <img
              src={facility.icon}
              alt={facility.title}
              className={classes.icon}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyUs;
