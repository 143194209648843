import React from "react";
import classes from "./SectionHeading.module.css";
import clsx from "clsx";

import { FaArrowRightLong } from "react-icons/fa6";

const SectionHeading = ({
  heading,
  title,
  textCenter,
  className,

  base0,
  type2,
  reverse,
}) => {
  return (
    <div
      className={clsx(
        classes.headingContainer,
        type2 && classes.type2Container,
        reverse && classes.reverse
      )}
    >
      <h1
        className={clsx(
          classes.heading,
          textCenter && classes.textCenter,
          type2 && classes.type2,
          className,

          base0 && classes.base0
        )}
      >
        {heading}{" "}
        {!type2 && (
          <>
            <br /> <span>Crypto Logic</span>
          </>
        )}
      </h1>

      <div className={classes.arrowContainer}>
        <FaArrowRightLong className={classes.icon} />
      </div>
    </div>
  );
};

export default SectionHeading;
