import React, { useState } from "react";
import classes from "./SubscribeUs.module.css";
import { Button, SectionHeading } from "../../common";
import clsx from "clsx";

const SubscribeUs = () => {
  const [email, setEmail] = useState("");
  return (
    <section className={classes.wrappper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.inputContainer}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="enter your email"
            className={classes.input}
          />
          <Button>Subscribe</Button>
        </div>
        <SectionHeading heading="Stay updated" reverse base0 />
      </div>
    </section>
  );
};

export default SubscribeUs;
