import React from "react";
import classes from "./OurServices.module.css";
import clsx from "clsx";
import { Button, Heading, SectionHeading, Text } from "../../common";

const OurServices = () => {
  const services = [
    {
      title: "Brand Building ",
      info: "Your brand is more than just a name—it's your identity in the marketplace. We work closely with C-level executives and teams to create a compelling brand narrative that resonates with your target audience and sets you apart from the competition.",
    },
    {
      title: "Community Building",
      info: "Engagement is key in the crypto world. We help you build and sustain a thriving community around your project through strategic content creation and tailored engagement tactics, while you are taking care of building your protocol/dapp.",
    },
    {
      title: "Marketing Strategies",
      info: "The crypto market is unlike any other market. We develop customized marketing strategies that speak the language of your audience, leveraging the latest trends and platforms to maximize your reach and impact.",
    },
    {
      title: "Pitch Decks & Fundraise Preparation",
      info: "Securing investment requires more than just a good idea—it demands a well-crafted story. We create professional pitch decks that effectively communicate your value proposition, helping you attract the right investors and secure the funding you need.",
    },
    {
      title: "One-on-One Consultancy",
      info: "Every project is unique, and so is our approach. Our one-on-one consultancy service offers personalized guidance on entering the crypto market, understanding the key terms and concepts, & navigating the steps necessary to launch and grow your project. Whether you need short-term advice or long-term support, we’re here to help.",
    },
  ];

  return (
    <section className={classes.wrapper} id="services">
      <div className={clsx(classes.container, "container")}>
        <SectionHeading type2 heading="Our Services" />
        <div className={classes.services}>
          {services?.map((service, i) => (
            <div
              className={clsx(
                classes.service,

                services.length % 2 === 0 && classes.even,
                services.length % 2 === 1 && classes.even,
                services.length - 1 === i && classes.lastItem,
                services.length - 2 === i && classes.secondLastItem,
                services.length % 3 === 2 && classes.myService,
                services.length % 3 === 1 && classes.myService2,
                services.length === 4 && classes.onlyFour
              )}
              key={i}
            >
              <Heading xl4 base950 className={classes.heading}>
                {service.title}
              </Heading>
              <Text xl base950 className={classes.info}>
                {service.info}
              </Text>
            </div>
          ))}
        </div>{" "}
        <Button href="https://t.me/taisiya_shuykova">Get your answers</Button>
      </div>
    </section>
  );
};

export default OurServices;
