import React from "react";
import classes from "./Meet.module.css";
import { Heading, SectionHeading, Text } from "../../common";
import clsx from "clsx";
import { founderAndCeoImg } from "../../../images";

const Meet = () => {
  return (
    <section className={classes.wrapper} id="team">
      <div className={clsx(classes.container, "container")}>
        <div className={classes.infoContainer}>
          <SectionHeading heading="Meet" />
          <Text xl3 base950>
            Taisiya Shuykova is the visionary behind Crypto Logic Agency. With
            over five years of experience in the crypto industry, Taisiya has
            been instrumental in helping traditional businesses transition into
            the blockchain world.
          </Text>{" "}
          <Text xl3 base950>
            Her expertise spans marketing, business development, and strategic
            consulting, making her a trusted advisor for start-ups and
            established companies alike.
          </Text>
        </div>{" "}
        <div className={classes.ceoWrapper}>
          <div className={classes.ceoInfo}>
            <img
              src={founderAndCeoImg}
              alt="Taisiya Shuykova"
              className={classes.img}
            />
            <Heading regular xl5 className={classes.name}>
              Taisiya Shuykova
            </Heading>
            <Heading regular xl2 base950>
              Founder & Chief Executive Officer
            </Heading>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Meet;
