import React from "react";
import classes from "./Home.module.css";
import HeroSection from "../../components/Home/HeroSection/HeroSection";
import AboutUs from "../../components/Home/AboutUs/AboutUs";
import OurServices from "../../components/Home/OurServices/OurServices";
import WhyUs from "../../components/Home/WhyUs/WhyUs";
import Testimonial from "../../components/Home/Testimonial/Testimonial";
import Meet from "../../components/Home/Meet/Meet";
import OurBlog from "../../components/Home/OurBlog/OurBlog";
import SubscribeUs from "../../components/Home/SubscribeUs/SubscribeUs";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  return (
    <main>
      <HeroSection />
      <AboutUs />
      <OurServices />
      <WhyUs />
      <Testimonial />
      <Meet />
      <OurBlog />
      <div className={classes.subscribeAndFooter}>
        <SubscribeUs />
        <Footer />
      </div>
    </main>
  );
};

export default Home;
